<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          <v-card-title>Testing Tools</v-card-title>
          <v-card-subtitle
            >Development & Sandbox Environments Only</v-card-subtitle
          >
          <v-card-text>
            <v-flex xs12 my-3>
              <v-card class="py-3 px-3">
                <v-card-text>
                  <p>
                    <strong>WARNING:</strong> This will query for subscription
                    bookings set to expire in three days from now. Payments will
                    attempt to be processed, and emails will be sent.
                  </p>
                  <v-btn small color="primary" @click="getRecurringPayments()"
                    >Process Recurring Payments</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 my-3>
              <v-card class="py-3 px-3">
                <v-card-text>
                  <p>
                    <strong>WARNING:</strong> This will query for classic
                    bookings set to expire in either 8 or 32 days from now.
                    Notification emails will be sent.
                  </p>
                  <v-btn small color="primary" @click="getExpiringBookings()"
                    >Send Expiring Notification Emails</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 my-3>
              <v-card class="py-3 px-3">
                <v-card-text>
                  <p>
                    <strong>WARNING:</strong> This will query for overdue
                    bookings, and will change their status to 'Overdue'.
                  </p>
                  <v-btn small color="primary" @click="updateOverdueBookings()"
                    >Update Overdue Bookings</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 my-3>
              <v-card class="py-3 px-3">
                <v-card-text>
                  <p>
                    <strong>WARNING:</strong> This will query for stored credit
                    cards that have been recently deleted, and will send a
                    warning email to the customer.
                  </p>
                  <v-btn small color="primary" @click="getDeletedCreditCards()"
                    >Send Deleted Card Emails</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 my-3>
              <v-card class="py-3 px-3">
                <v-card-text>
                  <p>
                    <strong>WARNING:</strong> This will delete all bookings, all
                    payment links and set all units as 'Available'.
                  </p>
                  <v-btn small color="primary" @click="resetBookingsAndUnits()"
                    >Reset booking, payment links and unit data</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-flex>

            <!--             <v-flex xs12 my-3>
              <v-card class="py-3 px-3">
                <v-card-text>
                  <p><strong>Date testing</strong></p>
                  <p>
                    This tool will allow you select an end date, and view the
                    calculated end date upon subscription renewal.
                  </p>
                  <v-layout>
                                       <v-flex xs3>
                      <v-menu
                        v-model="dateTempFilterMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateTempFilter"
                            label="End Date"
                            clearable
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="primary"
                          v-model="dateTempFilter"
                          @input="dateTempFilterMenu = false"
                        ></v-date-picker>
                      </v-menu>
                      <br> 
                      New end date: <br>
                      {{ compCheckoutDate }}
                    </v-flex>
                  </v-layout>
                                </v-card-text>
              </v-card>
            </v-flex> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
import helpers from "@/plugins/helpers";
const moment = require("moment");
export default {
  name: "Testing",
  components: {},
  data: () => ({
    dateStartMenu: false,
    dateStart: null,
    note: null,
    dtemps: [],
    headers: [
      { text: "Date", value: "date" },
      { text: "Note", value: "note" }
    ],
    dateTempFilterMenu: false,
    dateTempFilter: null
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    environment() {
      return process.env.VUE_APP_ENVIRONMENT;
    },
    compDateStart() {
      return moment(`${this.dateStart}`);
    },
    compCheckoutDate() {
      const dateOfMonth = moment(this.dateTempFilter).format("DD");
      const dateMonth = moment(this.dateTempFilter).format("MM");
      const dateYear = moment(this.dateTempFilter).format("YYYY");

      // if date is on or after 29th, advance to the 1st of the following month
      if (dateOfMonth >= 29) {
        return moment(`${dateMonth} 01 ${dateYear}`)
          .add(2, "month")
          .format("DD MMM YYYY");
      } else {
        // otherwise, the date is plus one month
        return moment(this.dateTempFilter)
          .add(1, "month")
          .format("DD MMM YYYY");
      }
    },
    compCheckInDate() {
      return moment(this.compDateStart).format("DD MMM YYYY");
    },
    compBookingCreatedTime() {
      return moment().format("DD MMM YYYY");
    }
  },
  methods: {
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    },
    getRecurringPayments() {
      // temp dev function
      let vm = this;
      axios
        .get(vm.$root.urls.api + "/v4/bookings/expiring", {})
        .then(function(response) {
          vm.$toasted
            .show("Recurring Payments processed ", {
              type: "success",
              icon: "mdi-thumb-up"
            })
            .goAway(2500);
        })
        .catch(function(error) {
          vm.$toasted
            .show("Unable to query recurring payments ", {
              type: "error",
              icon: "mdi-thumb-down"
            })
            .goAway(2500);
        });
    },
    getExpiringBookings() {
      let vm = this;
      axios
        .get(vm.$root.urls.api + "/v3/expire-notify", {})
        .then(function(response) {
          vm.$toasted
            .show("Notification emails sent ", {
              type: "success",
              icon: "mdi-thumb-up"
            })
            .goAway(2500);
        })
        .catch(function(error) {
          vm.$toasted
            .show("Unable to send notification emails ", {
              type: "error",
              icon: "mdi-thumb-down"
            })
            .goAway(2500);
        });
    },
    updateOverdueBookings() {
      let vm = this;
      axios
        .get(vm.$root.urls.api + "/v3/overdue-update", {})
        .then(function(response) {
          vm.$toasted
            .show("Overdue bookings updated ", {
              type: "success",
              icon: "mdi-thumb-up"
            })
            .goAway(2500);
        })
        .catch(function(error) {
          vm.$toasted
            .show("Unable to update overdue bookings ", {
              type: "error",
              icon: "mdi-thumb-down"
            })
            .goAway(2500);
        });
    },
    getDeletedCreditCards() {
      let vm = this;
      axios
        .get(vm.$root.urls.api + "/v3/deleted-cards", {})
        .then(function(response) {
          vm.$toasted
            .show("Deleted card emails sent ", {
              type: "success",
              icon: "mdi-thumb-up"
            })
            .goAway(2500);
        })
        .catch(function(error) {
          vm.$toasted
            .show("Unable to send deleted card emails ", {
              type: "error",
              icon: "mdi-thumb-down"
            })
            .goAway(2500);
        });
    },
    resetBookingsAndUnits() {
      let vm = this;
      let payload = {
        environment: vm.environment
      };
      axios
        .post(vm.$root.urls.api + "/v3/resetBookingsAndUnits", {
          payload
        })
        .then(function(response) {
          vm.$toasted
            .show("Booking and unit data has been reset ", {
              type: "success",
              icon: "mdi-thumb-up"
            })
            .goAway(2500);
        })
        .catch(function(error) {
          vm.$toasted
            .show("Unable to reset booking and unit data ", {
              type: "error",
              icon: "mdi-thumb-down"
            })
            .goAway(2500);
        });
    },
    addTestDate() {
      let vm = this;
      let payload = {
        note: vm.note
      };
      axios
        .post(vm.$root.urls.api + "/dev/dtemp", {
          payload
        })
        .then(function(response) {
          vm.$toasted
            .show("Test date added", {
              type: "success",
              icon: "mdi-thumb-up"
            })
            .goAway(2500);
          vm.note = null;
          vm.dtemps.push(response.data);
        })
        .catch(function(error) {
          vm.$toasted
            .show("Unable to add a test date ", {
              type: "error",
              icon: "mdi-thumb-down"
            })
            .goAway(2500);
        });
    },
    getTestDates() {
      let vm = this;
      // query api for dates
      let filter = vm.dateTempFilter
        ? moment(vm.dateTempFilter).format()
        : null;
      axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
      axios
        .get(vm.$root.urls.api + "/dev/dtemp", { params: { filter } })
        .then(function(results) {
          let data = results.data;
          vm.dtemps = data;
        });
    },
    formatUTC(d) {
      return moment(d)
        .utc()
        .format("DD MMM YYYY");
    },
    localDateTime(d) {
      return moment(d).local();
    }
  },
  mounted: function() {
    this.getTestDates();
  },
  watch: {
    dateTempFilter(val) {
      console.log(val);
      this.getTestDates();
    }
  }
};
</script>
<style lang="scss" scoped>
strong {
  font-weight: bold;
  color: #9c1b1f;
}
</style>
